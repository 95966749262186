<template>
    <div class="profile-image_wrap" style="position: relative">
        <figure class="profile-image" :class="`profile-image--${userStatus}`" width="48" v-if="image">
            <img :src="image" />
        </figure>
        <span v-else class="profile-image profile-image--placeholder">{{ initials }}</span>

        <span class="actions">
        <label class="action action--edit action--image">
            <inline-svg :src="require('@/assets/images/ic_edit.svg')" />
            <input type="file" v-on:change="uploadImage" />
        </label>
        <span class="action action--delete" @click="deleteImage" v-if="image"><inline-svg :src="require('@/assets/images/ic_delete.svg')" width="16" height="16" /></span>
    </span>
    </div>
    <h2 class="profile-fullname"><span v-text="firstname + ' ' + lastname"></span></h2>
    <p class="profile-info" v-html="division" v-if="division"></p>

    <ConfirmDialog ref="confirmDialog" />
</template>

<script>
import { mapGetters } from 'vuex';
import { USER_UPDATE } from '@/utils/store/actions/user';
import { defineAsyncComponent } from 'vue';

export default {
	name: 'profileheader',
    components:{
		ConfirmDialog : defineAsyncComponent(()=>import('@/components/Plugins/ConfirmDialog')),
	},
	props: {
		image: {
			default: false
		},
		firstname: {
			default: '',
			required: true
		},
		lastname: {
			default: '',
			required: true
		},
		division: {
			default: ''
		}
	},
	computed: {
		...mapGetters([ 'userStatus' ]),
		initials: function () {
			return this.firstname.substr(0, 1) + this.lastname.substr(0, 1);
		}
	},
	methods: {
		uploadImage: function ( evt ) {
			let file = evt.target.files[ 0 ],
				formData = {
					image: file
				};

			this.$store.dispatch(USER_UPDATE, formData)
				.then(( response ) => {
					this.profile.image = response.user.image;
					this.$toast.success('Profilbild wurde gespeichert.',{position: 'top'})
				})
				.catch(( err ) => console.warn(err));
		},
		async deleteImage () {
			const ok = await this.$refs.confirmDialog.show({
				title: 'Profilbild löschen',
				message: 'Wollen Sie das Profilbild wirklich löschen?',
				okButton: 'Ja, löschen!',
			});

			if ( ok ) {
				this.$store.dispatch(USER_UPDATE, {
						image: 'delete'
					})
					.then(() => {
						this.$toast.success('Profilbild wurde gelöscht!');
					})
					.catch(( err ) => console.warn(err));
			}
		}
	}
};
</script>
